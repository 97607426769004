import React from 'react';
import '../style.less';

const Row = props => {
  return <div className='row'>{props.children}</div>;
};

const Column = props => {
  return (
    <div {...props} className='column'>
      {props.children}
      {/* 
        // @ts-ignore */}
    </div>
  );
};

export { Row, Column };
